import React from "react";
import { Route, Routes } from "react-router-dom";
import { Auth, InnerPages } from "../../layouts";
import { SignIn } from "../../pages";
import { APP_ROUTES } from "../../storage";
import {
  ImagePageSkins,
  HtmlPageSkins,
  GalleryFolder,
  GalleryFolders,
  ScriptEdit,
} from "../../pages/inner";
import { Page404 } from "../../pages/error";

const App = () => {
  return (
    <Routes>
      <Route element={<Auth />}>
        <Route path={APP_ROUTES.signIn.path} element={<SignIn />} />
      </Route>
      <Route path="/" element={<InnerPages />}>
        <Route index element={<ImagePageSkins />} />
        <Route path={APP_ROUTES.scriptsHTML.path} element={<HtmlPageSkins />} />
        <Route path={APP_ROUTES.gallery.path} element={<GalleryFolders />} />
        <Route
          path={APP_ROUTES.galleryFolder.path}
          element={<GalleryFolder />}
        />
        <Route path={APP_ROUTES.script.path} element={<ScriptEdit />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
export default App;
