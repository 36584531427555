import React from "react";
import { ReactComponent as Icon } from "./assets/icons/logout-line-icon.svg";
import * as SC from "./logout-button.style";
import {useNavigate} from "react-router-dom";
import { APP_ROUTES } from "../../../../../../storage";

const LogoutButton = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate(APP_ROUTES.signIn.path)
  }
  return (
    <SC.Button onClick={handleLogout}>
      <Icon />
    </SC.Button>
  );
};

export default LogoutButton;