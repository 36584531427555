import React, { useState } from "react";
import * as SC from "./modal-select-banner.style";
import { Folder, Folders } from "./components";

const ModalSelectBanner = (props: any) => {
  const [folderID, setFolderID] = useState();
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  return (
    <SC.ModalSelectBanner>
      {!isFolderOpen && (
        <Folders setFolderID={setFolderID} setIsFolderOpen={setIsFolderOpen} />
      )}
      {isFolderOpen && (
        <Folder
          setIsFolderOpen={setIsFolderOpen}
          id={folderID}
          setOpen={props.setOpen}
          setFormData={props.setFormData}
        />
      )}
    </SC.ModalSelectBanner>
  );
};

export default ModalSelectBanner;
