import React from "react";
import * as SC from "./page404.style";
import { Button } from "../../../ui";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../storage";
const Page404 = () => {
  return (
    <SC.Page>
      <SC.Title>404</SC.Title>
      <Button as={Link} to={APP_ROUTES.home.path}>
        Go Home
      </Button>
    </SC.Page>
  );
};

export default Page404;
