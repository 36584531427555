import React, { useEffect, useState } from "react";
import * as SC from "./form-edit-script.style";
import { Button, Input, Switcher, Title } from "../../ui";
import { Box } from "../box";
import { useSendRequest } from "../../system/settings";
import { generatePath, useParams } from "react-router-dom";
import { API_ROUTES } from "../../storage";
import { useQueryClient } from "react-query";
import { useMakeScript } from "../../helpers";

type Type = {
  initialValues: {
    title: string;
    css: string;
    breakpoint: string;
    mobile: number;
    active: number;
    code: string;
    created_at: string;
    hash: string;
    html: 0 | 1;
  };
  refetch: any;
};
const FormEditScript: React.FC<Type> = ({ initialValues, refetch }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { createScript: updateScript, isLoading } = useMakeScript(
    !!initialValues.html
  );

  useEffect(() => {
    if (!isLoading) {
      refetch();
      queryClient.invalidateQueries([API_ROUTES.scripts.url]);
    }
  }, [isLoading]);
  const [formData, setFormData] = useState({
    title: initialValues.title,
    css: initialValues.css,
    // breakpoint: initialValues.breakpoint || 1023,
    mobile: initialValues.mobile,
    active: initialValues.active,
    hash: initialValues.hash,
    id,
  });

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    updateScript(formData);
  };
  const SCRIPT_COPY_TEMPLATE = `<script defer src="${initialValues.code}"></script>`;
  const MOBILE_DIV_EXAMPLE = `<div id="rotor-mobile-content"></div>`;

  return (
    <Box>
      <SC.Form onSubmit={handleSubmit}>
        <Title text="Script controls" />
        <Input
          placeholder="Title"
          name="title"
          value={formData.title}
          onChange={(e) => handleChange("title", e.currentTarget.value)}
          required
        />

        <Input
          placeholder="CSS"
          name="css"
          as="textarea"
          value={formData.css || ""}
          onChange={(e) => handleChange("css", e.currentTarget.value)}
        />
        {/*<Input*/}
        {/*  placeholder="Breakpoint (1023 *Example)"*/}
        {/*  name="breakpoint"*/}
        {/*  value={formData.breakpoint}*/}
        {/*  onChange={(e) => handleChange("breakpoint", e.currentTarget.value)}*/}
        {/*  required*/}
        {/*  disabled={!formData.mobile}*/}
        {/*/>*/}
        <Switcher
          active={!!formData.mobile}
          label="For mobile"
          onChange={() => handleChange("mobile", !!formData.mobile ? 0 : 1)}
        />
        <Switcher
          active={!!formData.active}
          label="Script is active"
          onChange={() => handleChange("active", !!formData.active ? 0 : 1)}
        />

        <Title text="Script check list" style={{ marginTop: "30px" }} />
        <SC.AdditionalInfo>
          <li>
            <span>Title:</span> <span>{formData.title}</span>
          </li>
          <li>
            <span>CSS:</span> <span>{formData.css}</span>
          </li>
          <li>
            <span>For:</span>
            <span>
              {!!formData.mobile ? "Mobile devices" : "Desktop"}
              <br />
              {!!formData.mobile && (
                <b>
                  * Partner must include this code into his website
                  <br />
                  <code>{MOBILE_DIV_EXAMPLE}</code>
                  <br />
                </b>
              )}
            </span>
          </li>
          <li>
            <span>Is active:</span>{" "}
            <span>{!!formData.active ? "Yes" : "No"}</span>
          </li>
          <li>
            <span>Script:</span> <span>{SCRIPT_COPY_TEMPLATE}</span>
          </li>
          <li>
            <span>Created:</span> <span>{initialValues.created_at}</span>
          </li>
        </SC.AdditionalInfo>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? "Processing" : "Update"}
        </Button>
      </SC.Form>
    </Box>
  );
};

export default FormEditScript;
