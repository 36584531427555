import styled, { css } from "styled-components";
export const Title = styled.p(
  ({ theme }) => css`
    font-family: ${theme.font.title};
    font-size: 200px;
    margin-bottom: 40px;
    line-height: 1;
  `
);
export const Page = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
  `
);
