import React from "react";
const Preloader = ({ isLoading, isFull = false }) => {
  return (
    <>
      {isLoading && (
        <div>...</div>
      )}
    </>
  );
};

export default Preloader;
