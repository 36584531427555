import React from "react";
import { SystemLayout } from "../types";
import { ThemeProvider } from "styled-components";
import { ResetStyles } from "./reset-styles";
import { DefaultStyles } from "./default-styles";
import { theme } from "./theme";

const StyledComponents: React.FC<SystemLayout> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <ResetStyles />
      <DefaultStyles />
      {props.children}
    </ThemeProvider>
  );
};

export default StyledComponents;
