import styled, { css } from "styled-components";
export const Back = styled.div(
  ({ theme }) => css`
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    background: ${theme.dark.light};
    font-size: 14px;
    margin-bottom: 15px;
    display: inline-block;
    color: ${theme.gray.main};
    transition: all 0.2s ease;
    border-radius: 4px;
    &:hover {
      background: ${theme.dark.main};
    }
  `
);
export const ImagesGrid = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    > * {
      width: 50%;
      padding: 0 10px;
      padding-bottom: 20px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    button {
      border: none;
      background: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        transform: scale(0.975);
        opacity: 0.75;
      }
    }
  `
);
