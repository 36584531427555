import React, { useEffect, useState } from "react";
import * as SC from "./form-create-new-script.style";
import { Button, Input, Title } from "../../ui";
import { useMakeScript } from "../../helpers";

const FormCreateNewScript = ({ setIsOpen, isHTML }: any) => {
  const { createScript, isLoading, isSuccess } = useMakeScript(isHTML);
  const [title, setTitle] = useState("");

  const handleScriptCreating = (e: any) => {
    if (isLoading) return;
    e.preventDefault();
    createScript({
      title,
      code: "",
      css: "",
      html: isHTML,
    });
    setTitle("");
  };

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
    }
  }, [isSuccess]);

  return (
    <SC.CreateScriptForm onSubmit={handleScriptCreating}>
      <Title text="Create new script" />
      <Input
        placeholder="Script title"
        name="title"
        required
        value={title}
        onChange={(e) => setTitle(e.currentTarget.value)}
      />
      <Button type="submit" disabled={isLoading}>
        {isLoading ? "Processing..." : "Create"}
      </Button>
    </SC.CreateScriptForm>
  );
};

export default FormCreateNewScript;
