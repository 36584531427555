import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import * as SC from "./inner-pages.style";
import { Header } from "./components";
import { APP_ROUTES } from "../../storage";

const InnerPages = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setHasAccess(true);
    } else {
      navigate(APP_ROUTES.signIn.path);
    }
  }, []);

  return (
    <>
      <Header />
      <SC.Container>{hasAccess && <Outlet />}</SC.Container>
    </>
  );
};

export default InnerPages;
