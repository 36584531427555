import styled, { css } from "styled-components";

export const ButtonStyle = styled.button(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    background: transparent;
    border: 1px solid transparent;
    padding: 10px 20px;
    line-height: 23px;
    outline: none;
    color: #fff;
    font-family: ${theme.font.basic};
    background: ${theme.primary.main};
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease;

    &.bordered {
      border-color: ${theme.primary.main};
      background: transparent;
    }
    &:hover {
      background: ${theme.primary.dark};
    }

    &:active {
      transform: scale(0.98);
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
  `
);
