import React from "react";
import * as SC from "./input.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  as?: any;
}
const Input: React.FC<Props> = ({ as, ...rest }) => {
  return <SC.InputStyle {...rest} as={as} />;
};

export default Input;
