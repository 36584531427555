import styled, { css } from "styled-components";

export const AuthFormStyle = styled.form(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 375px;
    max-width: 90%;
    border-radius: 4px;
    padding: 15px 20px;
    background: ${theme.dark.main};

    > * {
      + * {
        margin-top: 10px !important;
      }
    }
    h5 {
      font-weight: bold;
      margin-bottom: 15px;
    }
  `
);
