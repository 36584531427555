import axios from "axios";
import { APP_ROUTES } from "../../../../storage";

export const axiosSetup = () => {
  axios.interceptors.request.use(
    (config) => {
      return {
        ...config,
        responseType: "json",
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
    },
    (error) => Promise.reject(error)
  );
  axios.interceptors.response.use(
    (r) => r,
    function (error = { response: {}, config: {} }) {
      const { response, config } = error;

      if (response.status === 401) {
        window.location.pathname = APP_ROUTES.signIn.path;
      }
      return Promise.reject(error);
    }
  );
};
