import { useQuery } from "react-query";
import { apiRequest } from "../helpers";
import { ApiRequest, RequestOptions, ResultInterface } from "../types";

interface ApiHookInterface extends ApiRequest {
  keys?: unknown[] | string;
}

export const useReceiveRequest = (
  requestParams: ApiHookInterface,
  result?: ResultInterface,
  requestOptions?: RequestOptions
) => {
  const { keys, params } = requestParams;
  return useQuery([keys, params], () => apiRequest(requestParams), {
    ...requestOptions,
    onSuccess: (data) => result?.success && result.success(data),
    onError: (data) => result?.error && result.error(data),
    onSettled: (data) => result?.settled && result.settled(data),
  });
};
