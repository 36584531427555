import React, { useState } from "react";
import { useQueryClient } from "react-query";
import * as SC from "./form-promotion.style";
import { Button, Input, Modal, Title } from "../../ui";
import { ModalSelectBanner } from "../modal-select-banner";
import { useSendRequest } from "../../system/settings";
import { generatePath, useParams } from "react-router-dom";
import { API_ROUTES } from "../../storage";

type Type = {
  initialValues?: {
    id: string;
    title: string;
    link: string;
    banner?: string;
    html?: string;
    css?: string;
  };
  creating?: boolean;
  isHTML?: boolean;
};
const FormPromotion: React.FC<Type> = ({ initialValues, creating, isHTML }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const url = generatePath(API_ROUTES.promotion.url, {
    id,
  });
  const { mutate: sendPromotion, isLoading } = useSendRequest(
    {
      requestMethod: "post",
      url,
    },
    {
      success: () => {
        queryClient.invalidateQueries([url]);
      },
    }
  );
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: initialValues?.title || "",
    link: initialValues?.link || "",
    banner: initialValues?.banner || "",
    html: initialValues?.html || "",
    css: initialValues?.css || "",
  });
  const changeValue = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    const sendData = creating
      ? formData
      : { ...formData, id: initialValues?.id };
    sendPromotion(sendData);

    if (creating) {
      setFormData({
        title: "",
        link: "",
        banner: "",
        html: "",
        css: "",
      });
    }
  };
  return (
    <>
      <SC.Form onSubmit={handleSubmit}>
        <Title text="Edit promotion" />
        <Input
          placeholder="Title"
          name="title"
          value={formData.title}
          onChange={(e) => changeValue("title", e.currentTarget.value)}
          required
        />
        <Input
          placeholder="Link"
          name="link"
          value={formData.link}
          onChange={(e) => changeValue("link", e.currentTarget.value)}
          required
        />
        {isHTML && (
          <>
            <Input
              placeholder="HTML"
              name="html"
              value={formData.html}
              onChange={(e) => changeValue("html", e.currentTarget.value)}
              required
            />
            <Input
              placeholder="CSS"
              name="css"
              value={formData.css}
              onChange={(e) => changeValue("css", e.currentTarget.value)}
              required
            />
          </>
        )}
        <Input
          placeholder="Banner"
          name="banner"
          defaultValue={formData.banner}
          required
        />
        <Button
          type="button"
          onClick={() => setOpen(true)}
          className="bordered"
        >
          Select banner
        </Button>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? "Processing..." : "Submit"}
        </Button>
      </SC.Form>
      {open && (
        <Modal setOpen={setOpen}>
          <ModalSelectBanner setFormData={setFormData} setOpen={setOpen} />
        </Modal>
      )}
    </>
  );
};
export default FormPromotion;
