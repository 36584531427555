import React from "react";
import { useReceiveRequest } from "../../../../system/settings";
import { generatePath } from "react-router-dom";
import { API_ROUTES } from "../../../../storage";
import * as SC from "./folder.style";

const Folder = (props: any) => {
  const url = generatePath(API_ROUTES.folderImages.url, {
    id: props.id,
  });
  const { data } = useReceiveRequest({
    requestMethod: "get",
    url,
  });
  return (
    <div>
      <SC.Back onClick={() => props.setIsFolderOpen(false)}>Back</SC.Back>
      {data && (
        <SC.ImagesGrid>
          {data?.images?.map((item) => (
            <div key={item.id}>
              <button
                onClick={() => {
                  props.setOpen(false);
                  props.setFormData((prev) => ({ ...prev, banner: item.path }));
                }}
              >
                <img src={item.path} alt="" />
              </button>
            </div>
          ))}
        </SC.ImagesGrid>
      )}
    </div>
  );
};

export default Folder;
