import styled, { css } from "styled-components";
import { FolderCardStyle, IconFolder } from "../folder-card";

export const Grid = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    > * {
      width: calc(100% / 3);
      padding: 0 10px;
      padding-bottom: 20px;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &:first-child {
        ${IconFolder} {
          svg {
            path {
              fill: ${theme.secondary.dark};
            }
          }
        }
        ${FolderCardStyle} {
          color: ${theme.secondary.dark};
        }
      }
    }
  `
);
