import React from "react";
import { Box } from "../box";
import * as SC from "./under-development-message.style";
import { Button } from "../../ui";
const UnderDevelopmentMessage = (props: any) => {
  return (
    <Box>
      <SC.MessageTitle>
        This functionality is under development and will be available to you
        very soon!
      </SC.MessageTitle>
      <Button onClick={() => props.setOpen(false)}>OK</Button>
    </Box>
  );
};

export default UnderDevelopmentMessage;
