import { createGlobalStyle, css } from "styled-components";

export const DefaultStyles = createGlobalStyle`
  ${({ theme }) => css`
    body {
      min-height: 100vh;
      overflow-x: hidden;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      line-height: 1;
      margin: 0;
      padding: 0;
      background: ${theme.dark.light};
      color: ${theme.gray.main};
    }

    a {
      color: ${theme.primary.main};

      &:hover {
        text-decoration: none;
      }
    }

    [disabled] {
      opacity: 0.5;
      cursor: default !important;
      pointer-events: none !important;
      user-select: none !important;
    }
  `}
`;
