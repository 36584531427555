import React, { useCallback, useEffect, useState } from "react";
import * as SC from "./modal.style";

type Type = {
  children: React.ReactNode;
  setOpen: (v: boolean) => void;
};
const Modal: React.FC<Type> = ({ children, setOpen }) => {
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);
  return (
    <SC.ModalStyle>
      <SC.ModalOverlay onClick={() => setOpen(false)} />
      <SC.ModalContent>{children}</SC.ModalContent>
    </SC.ModalStyle>
  );
};

export default Modal;
