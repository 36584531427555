import React from "react";
import * as SC from "./gallery-card-preview.style";

type Type = {
  title: string;
  path: string;
  created_at: string;
};
const GalleryCardPreview: React.FC<Type> = ({ title, created_at, path }) => {
  return (
    <SC.GalleryCard>
      <SC.GalleryCardImage>
        <img src={path} alt="" />
      </SC.GalleryCardImage>
      <SC.GalleryCardTitle>{title}</SC.GalleryCardTitle>
      <SC.GalleryCardDate>{created_at}</SC.GalleryCardDate>
    </SC.GalleryCard>
  );
};

export default GalleryCardPreview;
