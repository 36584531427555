import { useMutation } from 'react-query';
import { ApiRequest, ResultInterface } from '../types';
import { apiRequest } from '../helpers';

export const useSendRequest = (
  requestParams: ApiRequest,
  result?: ResultInterface
) => {
  return useMutation(
    (params: { [key: string]: unknown }) =>
      apiRequest({ ...requestParams, params }),
    {
      onSuccess: (data, requestedData) =>
        result?.success && result.success(data, requestedData),
      onError: (data, requestedData) =>
        result?.success && result.success(data, requestedData),
    }
  );
};
