import React from "react";
import { generatePath, useParams } from "react-router-dom";
import * as SC from "./script-edit.style";
import { ReactComponent as BackArrow } from "../../../assets/icons/back-arrow.svg";
import { useReceiveRequest } from "../../../system/settings";
import { API_ROUTES, APP_ROUTES } from "../../../storage";
import { Box, FormEditScript } from "../../../components";
import { Promotions } from "./components";

const ScriptEdit = () => {
  const { id } = useParams();
  const url = generatePath(API_ROUTES.script.url, {
    id,
  });
  const { data, refetch, isLoading } = useReceiveRequest({
    requestMethod: "get",
    url,
    keys: url,
  });

  return (
    <SC.Page>
      <SC.Top>
        <SC.BackButton to={APP_ROUTES.home.path}>
          <BackArrow />
        </SC.BackButton>
        <SC.Title>{data?.rotator.title || "Loading..."}</SC.Title>
      </SC.Top>
      {data && !isLoading && (
        <FormEditScript initialValues={data.rotator} refetch={refetch} />
      )}
      <Promotions isHTML={!!data?.rotator.html} />
    </SC.Page>
  );
};

export default ScriptEdit;
