export const APP_ROUTES = {
  home: {
    path: "/",
  },
  signIn: {
    path: "/sign-in",
  },
  scriptsHTML: {
    path: "/scripts/html",
  },
  scriptsCreate: {
    path: "/scripts/create",
  },
  script: {
    path: "/scripts/:id",
  },
  gallery: {
    path: "/gallery-folders",
  },
  galleryFolder: {
    path: "/gallery-folders/:id",
  },
  galleryFolderCreate: {
    path: "/gallery-folders/create",
  },
};
