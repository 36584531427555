import React, { useState } from "react";
import { useReceiveRequest } from "../../../system/settings";
import { generatePath, useParams } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../../storage";
import * as SC from "./gallery-folder.style";
import { ReactComponent as FoldersIcon } from "./assets/icons/folders.svg";
import { GalleryGrid } from "./components";
import { Button, Modal } from "../../../ui";
import { FormAddBanner } from "../../../components";

const GalleryFolder = () => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const url = generatePath(API_ROUTES.folder.url, {
    id,
  });

  const { data } = useReceiveRequest({
    requestMethod: "get",
    url,
    keys: url,
  });
  return (
    <SC.Folder>
      <SC.Top>
        <SC.BackButton to={APP_ROUTES.gallery.path}>
          <FoldersIcon />
        </SC.BackButton>
        <SC.Title>{data ? data.folder.title : "Loading..."}</SC.Title>
      </SC.Top>
      <Button className="bordered" onClick={() => setOpen(true)}>
        + Add banner
      </Button>
      <GalleryGrid />
      {open && (
        <Modal setOpen={setOpen}>
          <FormAddBanner setOpen={setOpen} />
        </Modal>
      )}
    </SC.Folder>
  );
};

export default GalleryFolder;
