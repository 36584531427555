import React from "react";
import * as SC from "./folder-card.style";
import { ReactComponent as Icon } from "./assets/icons/open-folder-outline-icon.svg";
import { ReactComponent as IconCreate } from "./assets/icons/add-folder-icon.svg";
import { generatePath, Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../../storage";
import { FolderCardStyle, IconFolder } from "./folder-card.style";

type Type = {
  title: string;
  id?: string;
  create?: boolean;
  onClick?: () => void;
};
const FolderCard: React.FC<Type> = ({ title, id, create, ...rest }) => {
  const linkPath = create
    ? APP_ROUTES.galleryFolderCreate.path
    : generatePath(APP_ROUTES.galleryFolder.path, {
        id,
      });
  return (
    <SC.FolderCardStyle to={linkPath} as={create ? "button" : Link} {...rest}>
      <SC.IconFolder>{create ? <IconCreate /> : <Icon />}</SC.IconFolder>
      <SC.Title>{title}</SC.Title>
    </SC.FolderCardStyle>
  );
};

export default FolderCard;
