import styled, { css } from "styled-components";
import { InputStyle } from "../../ui";
export const FormTitle = styled.p(
  ({ theme }) => css`
    font-size: 21px;
    font-family: ${theme.font.title};
    font-weight: bold;
    line-height: 1;
    margin-bottom: 15px;
  `
);
export const Form = styled.form(
  ({ theme }) => css`
    > * {
      + * {
        margin-top: 15px;
      }
    }

    ${InputStyle} {
      width: 100%;
    }
  `
);
