import { useEffect, useState } from "react";
import { useReceiveRequest } from "../../../../../../system/settings";
import { API_ROUTES } from "../../../../../../storage";

export const useInitGrid = (isHTML: boolean) => {
  const [searchTitle, setSearchTitle] = useState("");
  const [scripts, setScripts] = useState<any[]>([]);
  const { data, isLoading } = useReceiveRequest({
    requestMethod: "get",
    url: API_ROUTES.scripts.url,
    keys: API_ROUTES.scripts.url,
  });
  const rotatorsHTML = data?.rotators.filter((rotator) => !!rotator.html);
  const rotatorsImages = data?.rotators.filter((rotator) => rotator.html === 0);
  const rotatorsArray = isHTML ? rotatorsHTML : rotatorsImages;
  useEffect(() => {
    setScripts(rotatorsArray);
    setSearchTitle("");
  }, [data?.rotators]);

  const handleSearch = (e) => {
    setSearchTitle(e.currentTarget.value);
    if (!e.currentTarget.value) {
      setScripts(rotatorsArray);
    }
    setScripts(
      rotatorsArray.filter((item) =>
        item.title.toLowerCase().includes(e.currentTarget.value)
      )
    );
  };

  return { scripts, searchTitle, handleSearch, isLoading: isLoading && data };
};
