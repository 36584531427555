import React from "react";
import * as SC from "./header.style";
import { HeaderNav, LogoutButton } from "./components";

const Header = () => {
  return (
    <SC.Header>
      <HeaderNav />
      <LogoutButton />
    </SC.Header>
  );
};

export default Header;
