import React from "react";
import * as SC from "./folders.style";
import { useReceiveRequest } from "../../../../system/settings";
import { API_ROUTES } from "../../../../storage";
import { ReactComponent as FolderIcon } from "../../../../assets/icons/folder.svg";

const Folders = (props: any) => {
  const { data } = useReceiveRequest({
    requestMethod: "get",
    url: API_ROUTES.folders.url,
  });
  return (
    <SC.Folders>
      {data &&
        data?.folders?.map((item) => (
          <div key={item.id}>
            <SC.Folder
              onClick={() => {
                props.setFolderID(item.id);
                props.setIsFolderOpen(true);
              }}
            >
              <FolderIcon />
              {item.title}
            </SC.Folder>
          </div>
        ))}
    </SC.Folders>
  );
};

export default Folders;
