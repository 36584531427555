import React, { useEffect, useState } from "react";
import * as SC from "./header-nav.style";
import { APP_ROUTES } from "../../../../../../storage";
import { NavLink, useLocation } from "react-router-dom";
import { Modal } from "../../../../../../ui";
import { UnderDevelopmentMessage } from "../../../../../../components";
import { ReactComponent as BurgerMenuIcon } from "../../../../../../assets/icons/menu.svg";

const HeaderNav = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const navigationItems = [
    {
      title: "Image page skin",
      path: APP_ROUTES.home.path,
    },
    {
      title: "HTML page skin",
      path: APP_ROUTES.scriptsHTML.path,
    },
    {
      title: "Pre-roll video",
      path: "",
      disabled: true,
    },
    {
      title: "Gallery",
      path: APP_ROUTES.gallery.path,
    },
  ];

  useEffect(() => {
    setMobileNav(false);
  }, [location.pathname]);
  return (
    <>
      <SC.BurgerMenu onClick={() => setMobileNav(!mobileNav)}>
        <BurgerMenuIcon />
      </SC.BurgerMenu>
      <SC.Nav className={`${mobileNav ? "mobile-active" : ""}`}>
        {navigationItems.map((item) => (
          <li key={item.title}>
            {!item.disabled && <NavLink to={item.path}>{item.title}</NavLink>}
            {item.disabled && (
              <button onClick={() => setOpen(true)}>{item.title}</button>
            )}
          </li>
        ))}
      </SC.Nav>

      {open && (
        <Modal setOpen={setOpen}>
          <UnderDevelopmentMessage setOpen={setOpen} />
        </Modal>
      )}
    </>
  );
};

export default HeaderNav;
