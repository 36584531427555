import styled, { css } from "styled-components";

export const GalleryCardImage = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    align-items: center;

    img {
      object-fit: cover;
    }
  `
);
export const GalleryCardTitle = styled.p(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    font-family: ${theme.font.title};
    padding: 0 5px;
    font-size: 12px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  `
);
export const GalleryCardDate = styled.p(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    padding: 5px;
    border-radius: 0 0 0 4px;
  `
);
export const GalleryCard = styled.div(
  ({ theme }) => css`
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  `
);
