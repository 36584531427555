import React from "react";
import { Outlet } from "react-router-dom";
import { AuthStyled } from "./auth.style";

const Auth: React.FC = () => {
  return (
    <AuthStyled>
      <Outlet />
    </AuthStyled>
  );
};

export default Auth;
