import React, { useState } from "react";
import { useQueryClient } from "react-query";

import * as SC from "./form-add-banner.style";
import { Box } from "../box";
import { Button, Input, Title } from "../../ui";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { useSendRequest } from "../../system/settings";
import { generatePath, useParams } from "react-router-dom";
import { API_ROUTES } from "../../storage";

const FormAddBanner = (props) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const url = generatePath(API_ROUTES.folderImages.url, {
    id,
  });
  const { mutate: saveImage, isLoading } = useSendRequest(
    {
      requestMethod: "post",
      url,
    },
    {
      success: () => {
        queryClient.invalidateQueries([url]);
      },
    }
  );
  const [imagePreview, setImagePreview] = useState("");
  const [formData, setFormData] = useState<any>({
    title: "",
    image: null,
  });
  const uploadImage = (files) => {
    const image = files[0];
    if (!image) return false;
    setFormData({ ...formData, image });
    setImagePreview(URL.createObjectURL(image));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    const setFileName = () => {
      const uploadedFileName = formData.image.name;
      let fileFormat = /[.]/.exec(uploadedFileName)
        ? /[^.]+$/.exec(uploadedFileName)
        : undefined;
      const fileName = formData.title.replaceAll(" ", "-").toLowerCase();

      return `${fileName}.${fileFormat}`;
    };
    const imageFile = new File([formData.image], setFileName());
    const data = new FormData();
    data.append("title", formData.title);
    data.append("image", imageFile);
    props.setOpen(false);
    saveImage(data);
    setFormData({
      title: "",
      image: null,
    });
    setImagePreview("");
  };
  return (
    <Box>
      <SC.Form onSubmit={handleSubmit}>
        <Title text="Add image to folder" />
        <Input
          placeholder="Image title"
          name="image"
          value={formData.title}
          required
          onChange={(e) =>
            setFormData({ ...formData, title: e.currentTarget.value })
          }
        />
        {!imagePreview && (
          <SC.UploadImageInput>
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                uploadImage(e.target.files)
              }
              required
            />
            <SC.UploadButton type="button">
              <UploadIcon />
            </SC.UploadButton>
          </SC.UploadImageInput>
        )}
        {imagePreview && (
          <SC.UploadedImage>
            <img src={imagePreview} alt="" />
          </SC.UploadedImage>
        )}
        {imagePreview && (
          <Button
            onClick={() => {
              setFormData({ ...formData, image: null });
              setImagePreview("");
            }}
            className="bordered"
          >
            Remove image
          </Button>
        )}
        <Button>Save to Folder</Button>
      </SC.Form>
    </Box>
  );
};

export default FormAddBanner;
