import React, { useState } from "react";
import * as SC from "./promotions.style";
import { Promotion } from "../promotion";
import { useReceiveRequest } from "../../../../../system/settings";
import { generatePath, useParams } from "react-router-dom";
import { API_ROUTES } from "../../../../../storage";
import { Button, Modal } from "../../../../../ui";
import { FormPromotion, Preloader } from "../../../../../components";

type Type = {
  isHTML?: boolean;
};
const Promotions: React.FC<Type> = ({ isHTML }) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const url = generatePath(API_ROUTES.promotion.url, {
    id,
  });
  const { data, isLoading } = useReceiveRequest({
    requestMethod: "get",
    url,
    keys: url,
  });
  return (
    <>
      <Button onClick={() => setOpen(true)} className="bordered">
        + Add promotion
      </Button>
      {isLoading ? (
        <Preloader isLoading />
      ) : (
        <SC.Grid>
          {data &&
            data.rotators.map((item) => (
              <div key={item.id}>
                <Promotion {...item} isHTML={isHTML} />
              </div>
            ))}
        </SC.Grid>
      )}

      {open && (
        <Modal setOpen={setOpen}>
          <FormPromotion creating isHTML={isHTML} />
        </Modal>
      )}
    </>
  );
};

export default Promotions;
