import React from "react";
import { BrowserRouter } from "react-router-dom";

import { SystemLayout } from "../types";

const Router: React.FC<SystemLayout> = ({ children }) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};

export default Router;
