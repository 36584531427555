import styled, { css } from "styled-components";

export const TitleStyle = styled.p(
  ({ theme }) => css`
    font-size: 21px;
    line-height: 1;
    font-family: ${theme.font.title};
    font-weight: bold;
    margin-bottom: 15px;
  `
);
