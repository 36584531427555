import styled, { css } from "styled-components";

export const SwitcherInput = styled.input(
  ({ theme }) => css`
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  `
);
export const SwitcherContent = styled.span(
  ({ theme }) => css`
    display: flex;
    align-items: center;
  `
);
export const SwitcherLabel = styled.span(
  ({ theme }) => css`
    margin-left: 10px;
  `
);
export const SwitcherBox = styled.span(
  ({ theme }) => css`
    width: 45px;
    height: 25px;
    border-radius: 30px;
    background: ${theme.dark.dark};
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(4px, -50%);
      width: 16px;
      height: 16px;
      background-color: ${theme.dark.light};
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
    }
  `
);
export const SwitcherStyle = styled.label(
  ({ theme }) => css`
    position: relative;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    &.active {
      ${SwitcherBox} {
        &::before {
          transform: translate(25px, -50%);
          background-color: ${theme.primary.dark};
        }
      }
    }
  `
);
