import React, { useEffect, useState } from "react";
import * as SC from "./scripts-grid.style";
import { useReceiveRequest } from "../../../../../system/settings";
import { API_ROUTES } from "../../../../../storage";
import { ScriptPreviewCard } from "../script-preview-card";
import { Preloader } from "../../../../../components";
import { CreateScriptPreviewCard } from "../../../../../components";
import { useInitGrid } from "./helpers";

type Type = {
  isHTML?: boolean;
};
const ScriptsGrid: React.FC<Type> = ({ isHTML }) => {
  const { scripts, searchTitle, handleSearch, isLoading } = useInitGrid(
    !!isHTML
  );
  return (
    <>
      <SC.GridSearch
        onChange={handleSearch}
        value={searchTitle}
        placeholder="Find by title"
      />
      <SC.Grid>
        <div>
          <CreateScriptPreviewCard isHTML={isHTML} />
        </div>
        {!isLoading ? (
          scripts?.map((item) => (
            <div key={item.id}>
              <ScriptPreviewCard {...item} />
            </div>
          ))
        ) : (
          <Preloader isLoading />
        )}
      </SC.Grid>
    </>
  );
};

export default ScriptsGrid;
