import React, { useState } from "react";
import { useQueryClient } from "react-query";

import * as SC from "./promotion.style";
import { Button, Modal, Switcher } from "../../../../../ui";
import { FormPromotion } from "../../../../../components";
import { useSendRequest } from "../../../../../system/settings";
import { generatePath, useParams } from "react-router-dom";
import { API_ROUTES } from "../../../../../storage";

type Type = {
  title: string;
  banner: string;
  link: string;
  id: string;
  active: number;
  isHTML: 0 | 1;
};
const Promotion: React.FC<Type> = (props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [isPromotionActive, setIsPromotionActive] = useState(!!props.active);
  const [open, setOpen] = useState<boolean>(false);
  const deleteUrl = generatePath(API_ROUTES.promotionDelete.url, {
    id,
  });
  const { mutate: deletePromotion, isLoading: isDeletingInProcess } =
    useSendRequest(
      {
        requestMethod: "post",
        url: deleteUrl,
      },
      {
        success: () => {
          queryClient.invalidateQueries([
            generatePath(API_ROUTES.promotion.url, {
              id,
            }),
          ]);
        },
      }
    );
  const { mutate: promotionStatus } = useSendRequest({
    requestMethod: "post",
    url: generatePath(API_ROUTES.promotionStatus.url, {
      id,
      type: isPromotionActive ? "deactivate" : "activate",
    }),
  });
  return (
    <>
      <SC.PromotionCard className={`${isDeletingInProcess ? "disabled" : ""}`}>
        <SC.PromotionTitle>{props.title}</SC.PromotionTitle>
        <SC.PromotionInfo>
          <li>
            <span>Image:</span>
            <span>
              <a href={props.banner} target="_blank" rel="noreferrer">
                {props.banner}
              </a>
            </span>
          </li>
          <li>
            <span>Link:</span>
            <span>
              <a href={props.link} target="_blank" rel="noreferrer">
                {props.link}
              </a>
            </span>
          </li>
          <li>
            <span>Active:</span>
            <span>
              <Switcher
                active={!!props.active}
                onChange={() => {
                  promotionStatus({
                    id: props.id,
                  });
                  setIsPromotionActive(!isPromotionActive);
                }}
              />
            </span>
          </li>
        </SC.PromotionInfo>
        <SC.PromotionControls>
          <Button className="bordered" onClick={() => setOpen(true)}>
            Edit
          </Button>
          <Button
            className="bordered"
            onClick={() =>
              deletePromotion({
                id: props.id,
              })
            }
          >
            Delete
          </Button>
        </SC.PromotionControls>
      </SC.PromotionCard>
      {open && (
        <Modal setOpen={setOpen}>
          <FormPromotion initialValues={{ ...props }} isHTML={!!props.isHTML} />
        </Modal>
      )}
    </>
  );
};

export default Promotion;
