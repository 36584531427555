import React from "react";
import { ReactQuery, StyledComponents, Router } from "../../layers";
import { SystemLayout } from "../types";
import { axiosSetup } from "../../settings";

const SetupSystem: React.FC<SystemLayout> = (props) => {
  axiosSetup();
  return (
    <ReactQuery>
      <StyledComponents>
        <Router>{props.children}</Router>
      </StyledComponents>
    </ReactQuery>
  );
};

export default SetupSystem;
