import React from "react";
import ReactDOM from "react-dom/client";
import { SetupSystem } from "./system/layers";
import { App } from "./components";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SetupSystem>
      <App />
    </SetupSystem>
  </React.StrictMode>
);
