import styled, { css } from "styled-components";

export const Folder = styled.button(
  ({ theme }) => css`
    padding: 15px;
    border-radius: 4px;
    background: ${theme.dark.light};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-family: ${theme.font.title};
    font-weight: bold;
    color: ${theme.gray.main};
    border: none;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease;
    svg {
      width: 25px;
      height: 25px;
      margin-bottom: 10px;

      path {
        fill: ${theme.gray.main};
        transition: all 0.2s ease;
      }
    }

    &:hover {
      background-color: ${theme.dark.main};
      color: ${theme.primary.main};
      svg {
        path {
          fill: ${theme.primary.main};
        }
      }
    }
  `
);
export const Folders = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    > * {
      width: calc(100% / 4);
      padding: 0 10px;
      padding-bottom: 20px;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  `
);
