import { useSendRequest } from "../system/settings";
import { API_ROUTES } from "../storage";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import raw from "raw.macro";

export const useMakeScript = (isHTML?: boolean) => {
  const queryClient = useQueryClient();
  const scriptFile = isHTML
    ? raw("../assets/js/dist/rotor-html.min.js")
    : raw("../assets/js/dist/rotor-images.min.js");

  console.log("===", scriptFile);
  const {
    mutate: generateScript,
    isLoading: isGenerateInProcess,
    isSuccess,
  } = useSendRequest(
    {
      requestMethod: "post",
      url: API_ROUTES.scriptGenerate.url,
    },
    {
      success: () => {
        queryClient.invalidateQueries([API_ROUTES.scripts.url]);
      },
    }
  );
  const { mutate: createScript, isLoading: isCreateInProcess } = useSendRequest(
    {
      requestMethod: "post",
      url: API_ROUTES.scripts.url,
    },
    {
      success: (data) => {
        const hash = data.rotator.hash || uuidv4();
        const fileName = `rotorfon-${hash}.min.js`;
        const storage = process.env.REACT_APP_BACKEND_URL?.startsWith(
          "https://stage"
        )
          ? "https://s3.stage-api-rotorfon.go.dogt.xyz"
          : "https://s3.rotorfon.go-prod.dogt.xyz";
        const rotorScript = scriptFile
          .replace("$ID$", `${data.rotator.id}`)
          .replace("$HASH$", `${hash}`)
          .replace("$STORAGE$", `${storage}`);

        const file = new File([rotorScript], fileName, {
          type: "text/javascript",
        });
        const scriptData = new FormData();
        scriptData.append("script", file);
        scriptData.append("id", data.rotator.id);
        scriptData.append("hash", hash);
        generateScript(scriptData);
      },
    }
  );

  return {
    createScript,
    isLoading: isGenerateInProcess || isCreateInProcess,
    isSuccess,
  };
};
