import React from "react";
import { AuthForm } from "../../../layouts";
import { Input, Title } from "../../../ui";
import { useSendRequest } from "../../../system/settings";
import { Preloader } from "../../../components";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../../storage";

const SignIn = () => {
  const navigate = useNavigate();
  const { mutate, isLoading, isError } = useSendRequest(
    {
      requestMethod: "post",
      url: API_ROUTES.signIn.url,
    },
    {
      success: (data) => {
        if (data.token) {
          const token = `Bearer ${data.token}`;
          localStorage.setItem("token", token);
          setTimeout(() => {
            navigate(APP_ROUTES.home.path);
          }, 100);
        }
      },
    }
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    mutate({
      email: e.target.email.value,
      password: e.target.password.value,
    });
  };
  return (
    <AuthForm onSubmit={handleSubmit}>
      <Preloader isLoading={isLoading} />
      <Title text="Sign in" />
      {!isLoading && isError && (
        <p
          style={{
            fontSize: "10px",
            margin: "10px 0",
            lineHeight: 1.5,
            color: "#e97272",
          }}
        >
          *Authorization error.
          <br /> Please, try again with correct Login and Password.
        </p>
      )}
      <Input placeholder="Login" name="email" required />
      <Input placeholder="Password" name="password" type="password" required />
    </AuthForm>
  );
};

export default SignIn;
