import styled, { css } from "styled-components";
import { InputStyle } from "../../ui";

export const FormTitle = styled.p(
  ({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.title};
    font-weight: bold;
    margin-bottom: 20px;
  `
);
export const Form = styled.form(
  ({ theme }) => css`
    width: 560px;
    max-width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    ${InputStyle} {
      width: 100%;
    }

    > * {
      + * {
        margin-top: 15px;
      }
    }
  `
);
