import styled, { css } from "styled-components";

export const Button = styled.button(
  ({ theme }) => css`
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
    background: ${theme.dark.light};
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: all 0.2s ease;

    @media screen and (max-width: 1200px) {
      position: relative;
      right: 0;
      transform: none;
    }
    svg {
      max-width: 80%;
      max-height: 20px;
      path {
        transition: all 0.2s ease;
      }
    }
    &:hover {
      background: ${theme.dark.main};
      path {
        fill: ${theme.secondary.main};
      }
    }
  `
);
