import React, { useState } from "react";
import * as SC from "./folders-grid.style";
import { useReceiveRequest } from "../../../../../system/settings";
import { API_ROUTES } from "../../../../../storage";
import { FolderCard } from "../folder-card";
import { Modal } from "../../../../../ui";
import { FormCreateFolder } from "../../../../../components";

const FoldersGrid = () => {
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useReceiveRequest({
    requestMethod: "get",
    url: API_ROUTES.folders.url,
    keys: API_ROUTES.folders.url,
  });
  return (
    <>
      <SC.Grid>
        <div>
          <FolderCard title="Add Folder" create onClick={() => setOpen(true)} />
        </div>
        {data &&
          data.folders.map((item) => (
            <div key={item.id}>
              <FolderCard {...item} />
            </div>
          ))}
      </SC.Grid>

      {open && (
        <Modal setOpen={setOpen}>
          <FormCreateFolder setOpen={setOpen} />
        </Modal>
      )}
    </>
  );
};

export default FoldersGrid;
