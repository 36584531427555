import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { Box } from "../box";
import * as SC from "./form-create-folder.style";
import { Button, Input, Title } from "../../ui";
import { useSendRequest } from "../../system/settings";
import { API_ROUTES } from "../../storage";

const FormCreateFolder = (props: any) => {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    title: "",
    folder: "",
  });
  const { mutate: createFolder, isLoading } = useSendRequest(
    {
      requestMethod: "post",
      url: API_ROUTES.folders.url,
    },
    {
      success: () => {
        queryClient.invalidateQueries([API_ROUTES.folders.url]);
        props.setOpen(false);
        setFormData({
          title: "",
          folder: "",
        });
      },
    }
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    createFolder(formData);
  };
  return (
    <Box>
      <SC.Form onSubmit={handleSubmit}>
        <Title text="Create new folder" />
        <Input
          placeholder="Folder title (This title will be displayed as folder name)"
          name="title"
          required
          value={formData.title}
          onChange={(e) =>
            setFormData({ ...formData, title: e.currentTarget.value })
          }
        />
        <Input
          placeholder="Folder storage name (Internal usage, hidden from view)"
          name="folder"
          required
          value={formData.folder}
          onChange={(e) =>
            setFormData({
              ...formData,
              folder: e.currentTarget.value.replaceAll(" ", "-").toLowerCase(),
            })
          }
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading ? "Processing" : "Create"}
        </Button>
      </SC.Form>
    </Box>
  );
};

export default FormCreateFolder;
