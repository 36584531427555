import styled, { css } from "styled-components";
import { ButtonStyle } from "../../../../../ui";

export const PromotionControls = styled.div(
  ({ theme }) => css`
    display: flex;
    transition: all 0.2s ease;
    opacity: 0.5;
    > * {
      flex: 1;
      + * {
        margin-left: 10px;
      }
    }

    ${ButtonStyle} {
      font-size: 12px;
      padding: 10px;
      line-height: 15px;

      &:last-child {
        border-color: ${theme.secondary.dark};
        &:hover {
          background: ${theme.secondary.dark};
        }
      }
    }
  `
);
export const PromotionInfo = styled.ul(
  ({ theme }) => css`
    font-size: 12px;
    line-height: 1.2;
    word-break: break-all;

    li {
      padding: 10px 0;
      + li {
        border-top: 1px solid rgba(250, 250, 250, 0.3);
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        &:first-child {
          width: 75px;
          opacity: 0.75;
          flex: none;
        }
      }
    }
  `
);
export const PromotionTitle = styled.div(
  ({ theme }) => css`
    font-size: 16px;
    font-family: ${theme.font.title};
    font-weight: bold;
    margin-bottom: 10px;
  `
);

export const PromotionCard = styled.div(
  ({ theme }) => css`
    padding: 20px;
    border-radius: 4px;
    transition: all 0.2s ease;
    background: ${theme.dark.main};
    &:hover {
      box-shadow: 0 0 20px ${theme.dark.main};
      ${PromotionControls} {
        opacity: 1;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
  `
);
