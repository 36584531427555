import React from "react";
import * as SC from "./box.style";

type Type = {
  children: React.ReactNode;
};
const Box: React.FC<Type> = ({ children, ...rest }) => {
  return <SC.BoxStyle {...rest}>{children}</SC.BoxStyle>;
};

export default Box;
