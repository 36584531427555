import styled, { css } from "styled-components";
import { ButtonStyle } from "../../ui";

export const Title = styled.p(
  ({ theme }) => css`
    font-family: ${theme.font.title};
    font-size: 21px;
    line-height: 1;
    margin-bottom: 30px;
  `
);
export const CreateScriptForm = styled.form(
  ({ theme }) => css`
    width: 300px;
    max-width: 100%;
    padding: 20px;

    > * {
      width: 100%;
    }

    ${ButtonStyle} {
      width: 150px;
      margin-top: 15px;
    }
  `
);
