import React from "react";
import * as SC from "./button.style";
import { ButtonStyle } from "./button.style";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  as?: any;
  to?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  as = "button",
  to,
  ...props
}) => {
  return (
    <SC.ButtonStyle {...props} as={as} to={to}>
      {children}
    </SC.ButtonStyle>
  );
};

export default Button;
