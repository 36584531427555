import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const IconFolder = styled.div(
  ({ theme }) => css`
    width: 125px;
    height: 125px;
    margin: 0 auto;
    svg {
      max-width: 100%;
      max-height: 100%;
      path {
        fill: ${theme.gray.main};
        transition: all 0.2s ease;
      }
    }
  `
);
export const Title = styled.p(
  ({ theme }) => css`
    font-family: ${theme.font.title};
    font-size: 21px;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  `
);
export const FolderCardStyle = styled(Link)(
  ({ theme }) => css`
    padding: 20px;
    border-radius: 4px;
    background: ${theme.dark.main};
    display: block;
    color: ${theme.gray.dark};
    text-decoration: none;
    text-align: center;
    transition: all 0.2s ease;
    border: none;
    width: 100%;
    cursor: pointer;

    &:hover {
      transform: scale(0.95);
      background: ${theme.dark.dark};
      color: ${theme.primary.dark};
      box-shadow: 0 0 20px ${theme.dark.dark};

      ${IconFolder} {
        svg {
          path {
            fill: ${theme.primary.dark};
          }
        }
      }
    }
  `
);
