import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Title = styled.div(
  ({ theme }) => css`
    font-family: ${theme.font.title};
    font-size: 32px;
    font-weight: bold;

    @media screen and (max-width: 1200px) {
      font-size: 21px;
    }
  `
);
export const Top = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  `
);
export const BackButton = styled(Link)(
  ({ theme }) => css`
    border: none;
    background: ${theme.dark.main};
    border-radius: 4px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: ${theme.gray.main};
    font-family: ${theme.font.basic};
    font-weight: bold;
    font-size: 14px;
    transition: all 0.2s ease;
    cursor: pointer;

    svg {
      width: 30px;
      height: 30px;
      path {
        fill: ${theme.gray.main};
        transition: all 0.2s ease;
      }
    }

    &:hover {
      background: ${theme.dark.dark};
      svg {
        path {
          fill: ${theme.secondary.dark};
        }
      }
    }
  `
);
export const Folder = styled.div(({ theme }) => css``);
