import React from "react";
import * as SC from "./gallery-grid.style";
import { useReceiveRequest } from "../../../../../system/settings";
import { generatePath, useParams } from "react-router-dom";
import { API_ROUTES } from "../../../../../storage";
import { GalleryCardPreview } from "../../../../../components";

const GalleryGrid = () => {
  const { id } = useParams();
  const url = generatePath(API_ROUTES.folderImages.url, {
    id,
  });
  const { data, isLoading } = useReceiveRequest({
    requestMethod: "get",
    url,
    keys: url,
  });
  return (
    <SC.Grid>
      {!isLoading &&
        data?.images.map((item) => (
          <div key={item.id}>
            <GalleryCardPreview {...item} />
          </div>
        ))}
      {isLoading && "..."}
    </SC.Grid>
  );
};

export default GalleryGrid;
