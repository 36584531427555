import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Icon = styled.div(
  ({ theme }) => css`
    margin-bottom: 10px;
    svg {
      width: 40px;
      height: 40px;
      path {
        fill: ${theme.secondary.dark};
        transition: all 0.2s ease;
      }
    }
  `
);
export const Title = styled.p(({ theme }) => css``);
export const Card = styled.div(
  ({ theme }) => css`
    height: 130px;
    border-radius: 4px;
    background: ${theme.dark.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${theme.secondary.dark};
    font-family: ${theme.font.title};
    font-size: 26px;
    text-decoration: none;
    transition: all 0.2s ease;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border-color: ${theme.secondary.dark};
      background: ${theme.dark.dark};
      box-shadow: 0 0 20px 0 ${theme.dark.dark};
      transform: scale(0.95);
    }
  `
);
