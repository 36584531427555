import styled, { css } from "styled-components";
import { InputStyle } from "../../ui";

export const FormTitle = styled.p(
  ({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.title};
  `
);

export const AdditionalInfo = styled.ul(
  ({ theme }) => css`
    width: 100%;
    li {
      font-size: 10px;
      line-height: 1.35;
      display: flex;
      align-items: flex-start;
      padding: 10px 0;
      word-break: break-all;
      + li {
        border-top: 1px solid rgba(250, 250, 250, 0.3);
      }
      span {
        &:first-child {
          width: 75px;
          flex: none;
          opacity: 0.75;
        }

        b {
          display: block;
          margin-top: 5px;
        }

        code {
          margin: 5px 0;
          display: inline-block;
          padding: 5px;
          border-radius: 4px;
          background: ${theme.dark.dark};
          font-family: monospace;
          color: ${theme.secondary.light};
        }
      }
    }
  `
);
export const Form = styled.form(
  ({ theme }) => css`
    background-color: ${theme.dark.main};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    > * {
      + * {
        margin-top: 15px;
      }
    }
    ${InputStyle} {
      width: 100%;
    }
  `
);
