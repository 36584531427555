import styled, { css } from "styled-components";

export const MessageTitle = styled.p(
  ({ theme }) => css`
    font-size: 18px;
    font-family: ${theme.font.title};
    font-weight: bold;
    margin-bottom: 20px;
  `
);
