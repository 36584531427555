import React, { useState } from "react";
import * as SC from "./create-script-preview-card.style";
import { ReactComponent as CreateIcon } from "./assets/icons/create-icon.svg";
import { APP_ROUTES } from "../../storage";
import { Modal } from "../../ui";
import FormCreateNewScript from "../form-create-new-script/form-create-new-script";

type Type = {
  isHTML?: boolean;
};
const CreateScriptPreviewCard: React.FC<Type> = ({ isHTML }) => {
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  return (
    <>
      <SC.Card
        to={APP_ROUTES.scriptsCreate.path}
        onClick={() => setCreateOpen(true)}
      >
        <SC.Icon>
          <CreateIcon />
        </SC.Icon>
        <SC.Title>Create new script</SC.Title>
      </SC.Card>
      {createOpen && (
        <Modal setOpen={setCreateOpen}>
          <FormCreateNewScript setIsOpen={setCreateOpen} isHTML={isHTML} />
        </Modal>
      )}
    </>
  );
};

export default CreateScriptPreviewCard;
