import styled, { css } from "styled-components";

export const BurgerMenu = styled.button(
  ({ theme }) => css`
    width: 35px;
    height: 35px;
    border-radius: 4px;
    background: ${theme.dark.light};
    border: none;
    padding: 0;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
      display: flex;
    }
    svg {
      width: 65%;
      height: 65%;

      path {
        fill: ${theme.gray.main};
      }
    }
  `
);
export const Nav = styled.ul(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 1200px) {
      display: none;
      background: ${theme.dark.dark};

      &.mobile-active {
        position: absolute;
        width: 100%;
        text-align: center;
        display: block;
        left: 0;
        top: 100%;
        z-index: 10;
      }
    }

    li > * {
      width: 100%;
      padding: 15px 10px;
      display: block;
      color: ${theme.gray.light};
      text-decoration: none;
      position: relative;
      transition: all 0.2s ease;
      font-family: ${theme.font.title};
      font-weight: normal;
      background: none;
      border: none;
      cursor: pointer;
      line-height: 1;
      text-align: center;
      &:hover {
        color: ${theme.gray.dark};
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        background: ${theme.primary.main};
        height: 2px;
        transform: scaleX(0.5);
        opacity: 0;
        transition: all 0.2s ease;
      }

      &:disabled {
        opacity: 0.75;
      }
      &.active {
        color: ${theme.primary.main};
        &::before {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
  `
);
