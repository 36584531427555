import styled, { css } from "styled-components";

export const ModalSelectBanner = styled.div(
  ({ theme }) => css`
    width: 770px;
    padding: 20px;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  `
);
