export const API_ROUTES = {
  signIn: {
    url: "/api/auth/login",
  },
  scripts: {
    url: "/api/rotator",
  },
  script: {
    url: "/api/rotator/:id",
  },
  scriptGenerate: {
    url: "/api/rotator/generate",
  },
  scriptDelete: {
    url: "/api/rotator/delete",
  },
  promotion: {
    url: "/api/rotator/:id/promotion",
  },
  promotionDelete: {
    url: "/api/rotator/:id/promotion/delete",
  },
  promotionStatus: {
    url: "/api/rotator/:id/promotion/:type",
  },
  folders: {
    url: "/api/folder",
  },
  folder: {
    url: "/api/folder/:id",
  },
  folderImages: {
    url: "/api/folder/:id/image",
  },
};
