import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ButtonStyle } from "../../../../../ui";

export const Title = styled(Link)(
  ({ theme }) => css`
    font-family: ${theme.font.title};
    font-size: 16px;
    font-weight: bold;
    color: ${theme.gray.dark};
    text-decoration: none;
    word-break: break-all;
    padding-right: 35px;
    transition: all 0.2s ease;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap; /* let the text wrap preserving spaces */
    min-height: 45px;
    line-height: 1.4;
    margin-bottom: 10px;

    &:hover {
      color: ${theme.primary.main};
    }
  `
);
export const ID = styled.p(
  ({ theme }) => css`
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    font-style: italic;
    color: ${theme.gray.dark};
    opacity: 0.5;
  `
);
export const Controls = styled.div(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 15px;
    padding-bottom: 15px;
    opacity: 0.3;
    transition: all 0.2s ease;

    > * {
      flex: 1;
      + * {
        margin-left: 10px;
      }
      &:last-child {
        flex: none;
        width: 50px;
        border-color: ${theme.secondary.dark};
        &:hover {
          background-color: ${theme.secondary.dark};
        }
      }
    }

    ${ButtonStyle} {
      font-size: 11px;
      padding: 5px 10px;
      line-height: 15px;
    }
  `
);
export const Card = styled.div(
  ({ theme }) => css`
    padding: 20px 15px;
    padding-bottom: 50px;
    height: 130px;
    border-radius: 4px;
    background: ${theme.dark.main};
    position: relative;
    display: block;
    transition: all 0.2s ease;
    border: 1px solid transparent;

    &:hover {
      border-color: ${theme.primary.main};
      background: ${theme.dark.dark};

      box-shadow: 0 0 20px 0 ${theme.dark.dark};
      transform: scale(0.95);

      ${Controls} {
        opacity: 1;
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }
  `
);
