import React from "react";
import { AuthFormStyle } from "./auth-form.style";
import { Button } from "../../../../ui";

const AuthForm: React.FC<React.FormHTMLAttributes<HTMLFormElement>> = (
  props
) => {
  return (
    <AuthFormStyle {...props}>
      {props.children}
      <Button type="submit">
        Submit
      </Button>
    </AuthFormStyle>
  );
};

export default AuthForm;
