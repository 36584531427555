import styled, { css } from "styled-components";

export const BoxStyle = styled.div(
  ({ theme }) => css`
    padding: 20px;
    border-radius: 4px;
    background: ${theme.dark.main};

    + * {
      margin-top: 20px;
    }
  `
);
