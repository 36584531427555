import styled, { css } from "styled-components";

export const ModalStyle = styled.div(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(1px);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px;
  `
);
export const ModalOverlay = styled.div(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `
);
export const ModalContent = styled.div(
  ({ theme }) => css`
    border-radius: 4px;
    background: ${theme.dark.dark};
    margin: auto;
    position: relative;
    z-index: 10;
    overflow-y: auto;

    @media screen and (max-width: 1200px) {
      width: 90%;
    }
  `
);
