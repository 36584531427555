export const theme = {
  primary: {
    light: "#88c0d0",
    main: "#81a1c1",
    dark: "#5e81ac",
  },
  secondary: {
    light: "#fddfb7",
    main: "#ebcb8b",
    dark: "#d08770",
  },
  gray: {
    light: "#eceff4",
    main: "#e5e9f0",
    dark: "#d8dee9",
  },
  dark: {
    light: "#434c5e",
    main: "#3b4252",
    dark: "#2e3440",
  },
  text: {
    primary: "#D8DEE9",
    secondary: "#acafb6",
  },
  font: {
    title: "Oswald, sans-serif",
    basic: "Inter, sans-serif",
  },
};
