import styled, { css } from "styled-components";
import { Input } from "../../../../../ui";

export const GridSearch = styled(Input)(
  ({ theme }) => css`
    width: 300px;
    max-width: 100%;
    margin-bottom: 15px;
    display: block;
  `
);
export const Grid = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    > * {
      width: calc(100% / 3);
      padding: 0 10px;
      padding-bottom: 20px;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  `
);
