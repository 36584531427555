import styled, { css } from "styled-components";
import { InputStyle } from "../../ui";

export const UploadImageInput = styled.label(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    border-radius: 4px;
    border: 1px dashed ${theme.primary.dark};
    cursor: pointer;
    display: block;
    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
    }
  `
);
export const UploadedImage = styled.div(
  ({ theme }) => css`
    //height: 250px;
    width: 100%;
    img {
      object-fit: contain;
      max-height: 100%;
    }
  `
);
export const UploadButton = styled.button(
  ({ theme }) => css`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;
    background: none;
    cursor: pointer;
    svg {
      width: 40px;
      height: 40px;

      path {
        fill: ${theme.gray.main};
      }
    }
  `
);
export const Form = styled.form(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 560px;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    > * {
      + * {
        margin-top: 15px;
      }
    }

    ${InputStyle} {
      width: 100%;
    }
  `
);
