import styled, { css } from "styled-components";

export const Header = styled.header(
  ({ theme }) => css`
    background: ${theme.dark.dark};
    margin-bottom: 15px;
    position: relative;

    @media screen and (max-width: 1200px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
    }
  `
);
