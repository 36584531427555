import React from "react";
import * as SC from "./title.style";

type Type = {
  text: string;
  style?: any;
};
const Title: React.FC<Type> = ({ text, ...rest }) => {
  return <SC.TitleStyle {...rest}>{text}</SC.TitleStyle>;
};

export default Title;
