import React, { useState } from "react";
import { useQueryClient } from "react-query";
import * as SC from "./script-preview-card.style";
import { API_ROUTES, APP_ROUTES } from "../../../../../storage";
import { generatePath, Link } from "react-router-dom";
import { Button } from "../../../../../ui";
import { useSendRequest } from "../../../../../system/settings";

type Type = {
  title: string;
  id: string;
  code: string;
};
const ScriptPreviewCard: React.FC<Type> = ({ title, id, code }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const queryClient = useQueryClient();
  const {
    mutate: deleteScript,
    isLoading,
    isSuccess,
  } = useSendRequest(
    {
      requestMethod: "post",
      url: API_ROUTES.scriptDelete.url,
    },
    {
      success: () => {
        queryClient.invalidateQueries([API_ROUTES.scripts.url]);
      },
    }
  );
  const linkPath = generatePath(APP_ROUTES.script.path, {
    id,
  });
  const SCRIPT_COPY_TEMPLATE = `<script defer src="${code}"></script>`;
  return (
    <SC.Card className={`${isDisabled ? "disabled" : ""}`}>
      <SC.Title to={linkPath}>{title}</SC.Title>
      <SC.ID>#{id}</SC.ID>
      <SC.Controls>
        <Button
          className="bordered"
          onClick={() => {
            navigator.clipboard.writeText(SCRIPT_COPY_TEMPLATE);
          }}
        >
          Copy Script{" "}
        </Button>
        <Button as={Link} to={linkPath} className="bordered">
          Edit
        </Button>
        <Button
          className="bordered"
          onClick={() => {
            setIsDisabled(true);
            deleteScript({
              id,
            });
          }}
        >
          Delete
        </Button>
      </SC.Controls>
    </SC.Card>
  );
};

export default ScriptPreviewCard;
