import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Top = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  `
);
export const BackButton = styled(Link)(
  ({ theme }) => css`
    height: 45px;
    width: 45px;
    background: ${theme.dark.main};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease;
    padding: 0;
    margin-right: 15px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
    path {
      fill: ${theme.gray.main};
      transition: all 0.2s ease;
    }

    &:hover {
      background: ${theme.dark.dark};

      path {
        fill: ${theme.secondary.dark};
      }
    }
  `
);
export const Title = styled.p(
  ({ theme }) => css`
    font-size: 32px;
    font-family: ${theme.font.title};
    font-weight: bold;

    @media screen and (max-width: 1200px) {
      font-size: 21px;
    }
  `
);
export const Page = styled.div(({ theme }) => css``);
