import styled, { css } from "styled-components";

export const Grid = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 20px 0;
    > * {
      width: calc(100% / 3);
      padding: 0 10px;
      padding-bottom: 20px;
      @media screen and (max-width: 1200px) {
        width: 50%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  `
);
