import React, { useState } from "react";
import * as SC from "./switcher.style";

type Type = {
  active?: boolean;
  label?: string;
  onChange?: () => void;
};
const Switcher: React.FC<Type> = ({ active = false, label, onChange }) => {
  const [isActive, setIsActive] = useState<boolean>(active);
  const handleChange = () => {
    onChange && onChange();
    setIsActive(!isActive);
  };
  return (
    <SC.SwitcherStyle className={`${isActive ? "active" : ""}`}>
      <SC.SwitcherInput
        onChange={handleChange}
        type="checkbox"
        defaultChecked={isActive}
      />
      <SC.SwitcherContent>
        <SC.SwitcherBox />
        {label && <SC.SwitcherLabel>{label}</SC.SwitcherLabel>}
      </SC.SwitcherContent>
    </SC.SwitcherStyle>
  );
};

export default Switcher;
