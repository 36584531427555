import styled, { css } from "styled-components";

export const InputStyle = styled.input(
  ({ theme }) => css`
    padding: 10px 20px;
    line-height: 23px;
    background: none;
    color: #fff;
    border: 1px solid ${theme.primary.dark};
    border-radius: 4px;
    outline: none;
    transition: all 0.2s ease;
    font-family: ${theme.font.basic};
    resize: vertical;
    &::placeholder {
      color: rgba(250, 250, 250, 0.5);
    }
    &:focus {
      border-color: ${theme.gray.dark};
    }
    &:read-only {
      opacity: 0.75;
    }
  `
);
